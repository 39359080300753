<template>
  <div class="moregoods">
    <top></top>
    <search></search>
    <!-- 主题内容 -->
    <div class="contain" id="shopCartMain">
      <el-table
        ref="multipleTable"
        :data="dataSource"
        style="width: 100%"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="quanxuan" label="全选" width="130">
          <template slot-scope="scope">
            <img :src="scope.row.logo" style="width: 100px" />
            <!-- <div v-else style="width: 100px height: 100px"></div> -->
          </template>
        </el-table-column>
        <el-table-column prop="goodsTitle" label="商品">
          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.goodsTitle }}</div>
              <div>规格：{{ scope.row.sellName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价" width="150">
          <template slot-scope="scope">
            ￥{{ Number(scope.row.price).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="数量" width="180">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.amount"
              :min="1"
              size="mini"
              :max="Number(scope.row.inventory)"
              @change="updateCarGoodsNumber(scope.row.amount, scope.row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="小计" width="150">
          <template slot-scope="scope">
            <span class="FBold">
              ￥{{ getTotalPrice(scope.row.price, scope.row.amount) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteCarRecord(scope.row.carId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="grid Mtp50 Mbm35">
        <el-pagination
          class="floatR Mtp20"
          background
          :total="ipagination.totalCount"
          :page-size="ipagination.size"
          :current-page="ipagination.current"
          @size-change="onSizeChange"
          @current-change="onPageChange"
          layout="total, sizes, prev, pager, next"
        ></el-pagination>
      </div>
      <div class="total-div">
        <div class="floatL checkAll">
          <el-link @click="toggleSelection(dataSource)">全选</el-link>
        </div>
        <div class="floatL co_999 unCheckALl">
          <el-link @click="deleteCarRecord(ids)">删除选中的商品</el-link>
        </div>
        <div class="floatR placeOrder" @click="placeOrder()">
          <span> 去下单 </span>
        </div>
        <div class="floatR totalPrice co_999">
          总价：<span class="F18 co_f00 FBold">￥{{ totalMoney }}</span>
        </div>
        <div class="floatR totalAmount co_999">
          已选择
          <span class="F16 co_f90 FBold">{{ multipleSelection.length }}</span>
          件商品
        </div>
      </div>
    </div>
    <bottom class="bottom"></bottom>
  </div>
</template>
<script>
import Bottom from "@/components/bottom.vue";
import Search from "@/components/search.vue";
import top from "@/components/top.vue";

import { listMixin } from "@/mixins/listMixins";
import { myCartList, deleteCarRecord, updateCarGoodsNumber } from "@/api/user";
import { mapGetters } from "vuex";
import BigNumber from "bignumber.js";
export default {
  mixins: [listMixin],
  components: {
    top,
    Search,
    Bottom,
  },
  name: "ShopCart",
  data() {
    return {
      extraParam: {
        userId: "",
      },
      url: {
        list: myCartList,
      },
      multipleSelection: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = "";
      for (let x = 0; x < this.multipleSelection.length; x++) {
        let result = this.multipleSelection[x];
        ids = ids + result.carId + ",";
      }
      return ids;
    },
    totalMoney() {
      let money = 0;
      for (let x = 0; x < this.multipleSelection.length; x++) {
        let result = this.multipleSelection[x];
        // money = money + result.money;
        //修改四舍五入
        money = new BigNumber(money).plus(new BigNumber(result.money));
      }
      money = money.toFixed(2);
      return money;
    },
  },
  mounted() {
    this.extraParam.userId = this.userInfo.id;
    this.loadData();
  },
  methods: {
    deleteCarRecord(carId) {
      if (!carId) {
        this.$message({
          type: "warning",
          message: "请至少选择一条数据！",
        });
        return false;
      }
      deleteCarRecord(carId).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.loadData(this.ipagination.current);
          // 获取购物车列表，获取购物车总数
          this.$store.dispatch("myCartList", this.userInfo.id);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 修改数量
    updateCarGoodsNumber(currentValue, row) {
      updateCarGoodsNumber({
        carId: row.carId,
        amount: currentValue,
        sellId: row.sellId,
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          for (let x = 0; x < this.multipleSelection.length; x++) {
            let result = this.multipleSelection[x];
            if (row.carId == result.carId) {
              result.amount = row.amount;
              // result.money = row.amount * result.price;
              result.money = this.getTotalPrice(result.price, row.amount);

              console.log(result.money);
            }
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //重新计算一次商品总价，四舍五入
      for (let x = 0; x < this.multipleSelection.length; x++) {
        let result = this.multipleSelection[x];
        result.money = this.getTotalPrice(result.price, result.amount);
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 下单操作
    placeOrder() {
      if (this.validatenull(this.ids)) {
        this.$message({
          type: "warning",
          message: "请选择下单的商品！",
        });
        return false;
      }
      this.$router.push({
        name: "OrderSettlement",
        query: {
          type: "car",
          ids: this.ids,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/style/variables.scss";

$thHeight: 50px;
#shopCartMain {
  margin: 20px auto 50px;
}
.grid {
  overflow: hidden;
}
.total-div {
  border: 1px solid #ebeef5;
  height: 60px;
}
.placeOrder {
  width: 119px;
  height: 100%;
  line-height: 57px;
  background-color: $primaryColor;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1.5px;
  span {
    font-size: 18px;
    color: #ffffff;
  }
}
.totalPrice {
  line-height: 57px;
  padding: 0 25px;
}
.totalAmount {
  line-height: 56px;
  margin-right: 50px;
}
.checkAll {
  margin: 15px 25px 0;
}
.unCheckALl {
  margin-top: 15px;
  margin-left: 20px;
}
</style>